.NavbarItems {
    background: rgb(3,0,54);
    background: linear-gradient(90deg, rgba(3,0,54,1) 0%, rgba(9,9,121,1) 59%, rgba(3,0,54,1) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 1.2rem;

}

.navbar-logo{
    color: #FFFFFF;
    justify-self: start;
    margin-left: 20px;
    margin-top: 15px;
    cursor: pointer;
}

.fa-record-vinyl {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 20px;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover{
    background-color: #0038c2;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #FFFFFF;
}

.menu-icon{
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems{
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 150px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        margin-top: 0;
    }

    .nav-menu.active {
        background: #001c7a;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #2752e0;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 20px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.8rem;
        cursor: pointer;

    }

    .fa-times {
        color: #FFFFFF;
        font-size: 2rem;
    }
}