* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
}

.nonScroll {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
    overflow-x: hidden;
    overflow-y: hidden;
}

.emptySpace {
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    color: rgb(0,0,0);
    background-color: rgb(0, 0, 0);
}

.emptySpaceExtra {
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    color: rgb(0,0,0);
    background-color: rgb(0, 0, 0);
    visibility: hidden;
}

.emptySpaceAbout {
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    color: linear-gradient(90deg, rgba(3,0,54,1) 0%, rgba(9,9,121,1) 39%, rgba(3,0,54,1) 100%);
    background-color: linear-gradient(90deg, rgba(3,0,54,1) 0%, rgba(9,9,121,1) 39%, rgba(3,0,54,1) 100%);
}

.column-left {
    float: left;
    position: absolute;
    width: 30%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-top: 2px white solid;
    background-color: black;
    
}

.column-right {
    float: right;
    width: 70%;
    height: 100vh;
    overflow-y: visible;
}

.column-rightAbout {
    float: right;
    width: 70%;
    height: 100vh;
    overflow-y: hidden;
}

.column-rightContact {
    float: right;
    width: 70%;
    height: 100vh;
    padding: 20px;
    padding-top: 40px;
    text-align: center;
    color: white;
    overflow-y: hidden;
    background: rgb(3,0,54);
    background: linear-gradient(90deg, rgba(7,4,88,255) 0%, rgba(9,9,121,1) 39%, rgba(3,0,54,1) 100%);
    border-top: 2px solid white;
    border-left: 2px solid white;
}

.columnImg {
    width: 35%;
    float: left;
    background: linear-gradient(90deg, rgba(7,4,88,255) 0, rgba(11,7,108,255) 100%);
    color: rgb(255, 255, 255);
    border-left: 2px solid white;
    border-top: 2px solid white;
    padding-top: 0px;
    overflow-y: hidden;
}

.columnInfo{
    text-align: left;
    padding: 20px;
    padding-top: 50px;
    padding-left: 10%;
    border-top: 2px solid white;
    float: right;
    width: 65%;
    background: linear-gradient(90deg, rgba(11,7,108,255) 0%, rgba(9,9,121,1) 25%, rgba(3,0,54,1) 100%);
    color: rgb(255, 255, 255);
    overflow-y: hidden;
}

.row:after {
    content: "";
    display: table;
    clear: both;
    height: "100%vh";
}


.cardContent {
    flex: 1;
    padding-left: 2%;
}

.terms {
    display: flex;
    height: "100%vh";
    align-content: center;
    justify-content: center;

}


@media screen and (max-width: 960px){

    * {
        overflow-x: visible;
        overflow-y: visible;
    }
    .filterSectionApp {
        visibility: hidden;
        width: 100%;
        height: "100%vh";
        background: rgb(0,0,0);
        color: rgb(255, 255, 255);
        text-align: center;
        padding: 10px;
        overflow-wrap: break-word;
        white-space: normal;
    }
    
    .filterSection {
        visibility: hidden;
        width: 100%;
        background: rgb(0,0,0);
        color: rgb(255, 255, 255);
        text-align: center;
        padding: 10px;
        overflow-wrap: break-word;
        white-space: normal;
        overflow-y: hidden;
    }

    .emptySpace {
        visibility: hidden;
    }

    .column-right {
        float: right;
        width: 100%;
        height: 100vh;
        overflow-y: visible;
    }

    .column-left {
        visibility: hidden;
    }

    .column-rightAbout {
        width: 100%;
        border-right: 2px white;
        border-bottom: 2px solid white;
    }

    .column-rightContact {
        width: 100%;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
    }

    .NavbarItems{
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 300px;
        position: absolute;
        top: 80px;
        left: -100%;
        transition: all 0.5s ease;
        opacity: 1;
        margin-top: 0;
    }

    .nav-menu.active{
        background: #001c7a;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #2752e0;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 20px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 1.8rem;
        cursor: pointer;

    }

    .fa-times {
        color: #FFFFFF;
        font-size: 2rem;
    }

    .emptySpaceExtra{
        visibility: visible;
    }
    
}

@media screen and (max-width: 500px){
    .cardContent {
        padding-left: 0px;
    }
}

.filterSectionApp {
    width: 100%;
    height: "100%vh";
    background: rgb(0,0,0);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 10px;
    overflow-wrap: break-word;
    white-space: normal;
}

.filterSection {
    width: 100%;
    background: rgb(0,0,0);
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 10px;
    overflow-wrap: break-word;
    white-space: normal;
    overflow-y: hidden;
}



.hr-line {
    border: 1px white solid;
    width: 100px;
    border-radius: 20px;
}

.hr-line2 {
    border: 1px white solid;
    width: 487px;
    border-radius: 20px;
}

.aboutHeadings {
    color: #e8e8e8;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
}

.aboutTitles {
    color: #fafafa;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 36px;
}

.aboutSection {
    width: 100%;
    height: "100%vh";
    background: linear-gradient(90deg, rgba(6,5,88,255) 0%, rgba(9,9,121,1) 39%, rgba(3,0,54,1) 100%);
    color: rgb(255, 255, 255);
    text-align: center;
    border: 2px solid black;
    padding: 10px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 20px;
}

.beatCard {
    background: rgb(3,0,54);
    background: linear-gradient(90deg, rgba(3,0,54,1) 0%, rgba(9,9,121,1) 39%, rgba(3,0,54,1) 100%);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: "100%vh";
    padding: 10px;
    text-align: left;
    border-top: solid 2px white;
    border-right: solid 2px white;
    border-left: solid 2px white;
    overflow: auto;
}


.cardTextTitle {
    color: white;
    font-size: 50px;
}

.cardText {
    color: white;
    font-size: 15px;
    padding-left: 5px;
    padding-bottom: 10px;
}

.cardImg {
    float: left;
    object-fit: cover;
    width: 200px;
    height: 200px;
    border-radius: 1%;
    border: 2px solid white;
}

.aboutImg {
    width: 100%;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
}

.contactImg{
    width: 40%;
}

.contactImgDiv {
    display: inline;
    padding: 10px;
}

.personalTextHeading {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 36px;
}

.personalText {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 26px;
}
.contactPrompt {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
}

.NavbarItems {
    background: rgb(3,0,54);
    background: linear-gradient(90deg, rgba(3,0,54,1) 0%, rgba(9,9,121,1) 59%, rgba(3,0,54,1) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 1.2rem;

}

.navbar-logo{
    color: #FFFFFF;
    justify-self: start;
    margin-left: 20px;
    margin-top: 15px;
    cursor: pointer;
}

.fa-record-vinyl {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 20px;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover{
    background-color: #0038c2;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #FFFFFF;
}

.menu-icon{
    display: none;
}